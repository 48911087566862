import React from 'react';
import { ProfileDropdown } from '../Dropdown';
import PrincipalNavbar from './PrincipalNavbar';

const NavbarTop = () => {
  return (
    <PrincipalNavbar>
      <div className="ml-auto flex-row">
        <ProfileDropdown />
      </div>
    </PrincipalNavbar>
  );
};

export default NavbarTop;
